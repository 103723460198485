<template>
  <v-row no-gutters>
    <v-col
      v-if="treeLength > 1"
      class="d-inline-block"
      :style="'max-width:' + treeLength * 15 + 'px'"
    >
      <span>|</span>
      <span v-for="line in treeLength" :key="line">__</span>
    </v-col>
    <v-col>
      <primaryTextfield
        v-if="parentType != 'array'"
        v-model="localValue"
        :fieldAttrInput="fieldAttributes.fieldAttrInput"
        :label="fieldAttributes.name"
        :templateContent="result"
      ></primaryTextfield>
    </v-col>
  </v-row>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";

export default {
  components: { primaryTextfield },
  props: ["result", "fieldAttributes", "checkReadOnly", "name", "value"],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    parentType() {
      let parentType = this.result.valueLine.parentType;

      return parentType;
    },
    treeLength() {
      let treeLength = 0;
      if (
        typeof this.result.fields.tree != "undefined" &&
        typeof this.result.valueLine.tree != "undefined"
      ) {
        let tree = this.result.valueLine.tree;
        let treeArray = tree.split(".");
        treeLength = treeArray.length;
      }
      return treeLength;
    },
  },
};
</script>